import { LanguageProp, MainMenuProp } from 'types';

export const imageFolder = '/assets/images/';

export const supportedLanguages: LanguageProp[] = [
    { url: '', text: 'En' },
    { url: '', text: 'Fr' },
    { url: '', text: 'De' }
];

export const mainMenu: MainMenuProp[] = [
    {
        text: 'Home',
        path: '',
        submenu: [
            { path: '/', text: 'Home - Map' },
            { path: '/index-slider', text: 'Home - Slider' },
            { path: '/index-slider-1', text: 'Home - Slider V2' },
            { path: '/index-map', text: 'Home - Map Only' },
            {
                path: '/index-slider-fullscreen',
                text: 'Home - Full Screen Slider'
            }
        ]
    },
    {
        text: 'Property Listings',
        path: '',
        submenu: [
            {
                path: '/pages/property-listing-grid',
                text: 'Property Listings - Grid'
            },
            {
                path: '/pages/property-listing-sidebar',
                text: 'Property Listings - Grid & Sidebar'
            },
            {
                path: '/pages/property-listing-rows',
                text: 'Property Listings - Rows'
            },
            {
                path: '/pages/property-listing-map',
                text: 'Property Listings - Map'
            },
            { path: '/pages/property-details', text: 'Property Details' }
        ]
    },
    { path: '/pages/compare', text: 'Property Compare' },
    {
        text: 'Agents',
        path: '',
        submenu: [
            { path: '/pages/agents', text: 'Agents Listing' },
            { path: '/pages/agent-details', text: 'Agent Details' }
        ]
    },
    {
        text: 'Gallery',
        path: '',
        submenu: [
            { path: '/pages/gallery?cols=2', text: 'Gallery - 2 Columns' },
            { path: '/pages/gallery?cols=3', text: 'Gallery - 3 Columns' },
            { path: '/pages/gallery?cols=4', text: 'Gallery - 4 Columns' }
        ]
    },
    {
        text: 'Blog',
        path: '',
        submenu: [
            { path: '/pages/blog', text: 'Blog - Right Sidebar' },
            { path: '/pages/blog?sidebar=left', text: 'Blog - Left Sidebar' },
            { path: '/pages/blog-details', text: 'Single Post' }
        ]
    },
    {
        text: 'Pages',
        path: '',
        submenu: [
            { path: '/pages/testimonials', text: 'Testimonials' },
            { path: '/pages/services', text: 'Services' },
            { path: '/pages/video-tour', text: 'Video Tour' },
            { path: '/pages/faq', text: 'FAQ' },
            { path: '/pages/404', text: '404' }
        ]
    },
    { path: '/pages/contact', text: 'Contact' }
];
