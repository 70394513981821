import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const colorPallet: { primary: string; secondary: string }[] = [
    { primary: 'bg-[#BFA249]', secondary: 'bg-[#1E4464]' },
    { primary: 'bg-[#ff6c39]', secondary: 'bg-[#222935]' },
    { primary: 'bg-[#50aee6]', secondary: 'bg-[#005588]' },
    { primary: 'bg-[#23B549]', secondary: 'bg-[#007A20]' }
];
const ThemeSwitcher: React.FC = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selectedTheme, setSelectedTheme] = useState<number>(0);
    const handleSelectedItem = (index: number) => {
        const htmlElement = document.querySelector('html');
        const theme = index !== 0 ? `style-${index}` : 'light';
        htmlElement?.setAttribute('data-theme', theme);
        setSelectedTheme(index);
    };
    return (
        <div
            className={`default-transition fixed top-1/2 z-30 bg-white w-[150px] text-center p-5 text-primary shadow-lg rounded-bl ${
                isOpen ? 'right-0' : '-right-[150px]'
            } last:[&>div]:mb-0`}>
            <div
                onClick={() => setIsOpen(!isOpen)}
                className="text-primary p-5 absolute -left-[56px] top-0 bg-white shadow-lg cursor-pointer rounded-l-lg">
                <FontAwesomeIcon icon={['fas', 'cog']} />
            </div>
            <div className="mb-5 text-black">Choose a color</div>
            {colorPallet.map((colorSet, index) => {
                return (
                    <div
                        onClick={() => handleSelectedItem(index)}
                        key={index}
                        className={`relative cursor-pointer border-2 overflow-hidden h-[30px] mb-5 ${
                            colorSet.primary
                        } ${
                            index === selectedTheme
                                ? 'border-black'
                                : 'border-transparent'
                        }`}>
                        <div
                            className={`${colorSet.secondary} absolute left-0 top-0 rotate-45 h-full w-full z-30`}></div>
                    </div>
                );
            })}
        </div>
    );
};
export default ThemeSwitcher;
