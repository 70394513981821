import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import Loading from './components/Loading';
// Pages
const HomeSlider = lazy(() => import('./pages/home-pages/slider'));
const HomeSliderWithSearch = lazy(() => import('./pages/home-pages/slider-1'));
const HomeSliderFullScreen = lazy(
    () => import('./pages/home-pages/slider-fullscreen')
);
const GridPropertyListing = lazy(
    () => import('./pages/property-listings/grid')
);
const GridSidebarPropertyListing = lazy(
    () => import('./pages/property-listings/grid-sidebar')
);
const RowSidebarPropertyListing = lazy(
    () => import('./pages/property-listings/row-sidebar')
);
const AgentListing = lazy(() => import('./pages/agent-listing'));
const AgentDetails = lazy(() => import('./pages/agent-details'));
const Gallery = lazy(() => import('./pages/gallery'));
const Blog = lazy(() => import('./pages/blog'));
const BlogDetails = lazy(() => import('./pages/blog-details'));
const Testimonials = lazy(() => import('./pages/testimonials'));
const Services = lazy(() => import('./pages/services'));
const VideoTour = lazy(() => import('./pages/video-tour'));
const Faq = lazy(() => import('./pages/faq'));
const NotFound = lazy(() => import('./pages/404'));
const Contact = lazy(() => import('./pages/contact'));
const Compare = lazy(() => import('./pages/compare'));
const MapPropertyListing = lazy(() => import('./pages/property-listings/map'));
const HomeMap = lazy(() => import('./pages/home-pages/map'));
const HomeMapFullScreen = lazy(
    () => import('./pages/home-pages/map-fullscreen')
);
const PropertyDetails = lazy(() => import('./pages/property-details'));
const SubmitProperty = lazy(() => import('./pages/submit-property'));

library.add(fab, fas, far);

const AppRouter: React.FC = () => {
    return (
        <Router>
            <Suspense fallback={<Loading />}>
                <Routes>
                    <Route path="/" element={<HomeMap />} />
                    <Route path="/index-slider" element={<HomeSlider />} />
                    <Route
                        path="/index-slider-1"
                        element={<HomeSliderWithSearch />}
                    />
                    <Route
                        path="/index-slider-fullscreen"
                        element={<HomeSliderFullScreen />}
                    />
                    <Route path="/index-map" element={<HomeMapFullScreen />} />
                    <Route
                        path="/pages/property-listing-grid"
                        element={<GridPropertyListing />}
                    />
                    <Route
                        path="/pages/property-listing-sidebar"
                        element={<GridSidebarPropertyListing />}
                    />
                    <Route
                        path="/pages/property-listing-rows"
                        element={<RowSidebarPropertyListing />}
                    />
                    <Route
                        path="/pages/property-listing-map"
                        element={<MapPropertyListing />}
                    />
                    <Route
                        path="/pages/property-details"
                        element={<PropertyDetails />}
                    />
                    <Route path="/pages/agents" element={<AgentListing />} />
                    <Route
                        path="/pages/agent-details"
                        element={<AgentDetails />}
                    />
                    <Route path="/pages/gallery" element={<Gallery />} />
                    <Route path="/pages/blog" element={<Blog />} />
                    <Route
                        path="/pages/blog-details"
                        element={<BlogDetails />}
                    />
                    <Route
                        path="/pages/testimonials"
                        element={<Testimonials />}
                    />
                    <Route path="/pages/services" element={<Services />} />
                    <Route path="/pages/video-tour" element={<VideoTour />} />
                    <Route path="/pages/faq" element={<Faq />} />
                    <Route path="/pages/contact" element={<Contact />} />
                    <Route path="/pages/compare" element={<Compare />} />
                    <Route
                        path="/pages/submit-property"
                        element={<SubmitProperty />}
                    />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </Suspense>
        </Router>
    );
};

export default AppRouter;
